import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FlatList, Pressable, Text, View, RefreshControl } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import { useColorScheme } from 'nativewind';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { PostFilter, EventCard, Layout, Loader, Pagination } from '../../components';
import {
  adaptiveContainerPaddingTop,
  isMobile,
  isMobileWeb,
  isTablet,
  isWeb,
  navHeight,
} from '../../utils/responsive';
import { Article, PaginatedResponse, Pagination as IPagination } from '../../client/interfaces';
import client from '../../client/client';
import { useMessage } from '../../contexts/Messages/MessageContext';
import {
  NavigationParamList,
  ScreenName,
  StackName,
  StackNavigationScreenProps,
} from '../../navigation/types';
import { distributeElements } from '../../utils';
import { cln } from '../../utils/classnames';
import { useResize } from '../../utils/resize';
import { ArticlesParamsContext } from '../../contexts/ArticlesParams/ArticlesParamsContext';
import { NavigationContextStack } from '../../contexts/NavigationContext/NavigationContextStack';

const emptyPagination: IPagination = {
  total: 0,
  perPage: 0,
  currentPage: 1,
  pageCount: 1,
  fromNumber: 0,
  toNumber: 0,
};

const ArticlesScreen: React.FC<
  StackNavigationScreenProps<NavigationParamList, ScreenName.ArticlesScreen>
> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { setMessage } = useMessage();
  const { isWidthBelowSmall, isWidthBelowLarge } = useResize();

  const insets = useSafeAreaInsets();

  const { stackRoute, setStackNavigation, setStackRoute, changedStack, setChangedStack } =
    useContext(NavigationContextStack);

  useEffect(() => {
    //We only clear the articles state when the changed stack is the one we're on
    //and if we have come from articlesScreen (list) screen (not the event screen).
    if (stackRoute && changedStack === StackName.ArticlesStack) {
      if (!isMobile || (isMobile && stackRoute?.name === 'list')) {
        emptyArticlesState();
      }
      setChangedStack(null);
    }
  }, [changedStack, stackRoute]);

  useFocusEffect(
    useCallback(() => {
      setStackRoute(route);
      setStackNavigation(navigation);
    }, [changedStack]),
  );

  const {
    getArticlesParams,
    setGetArticlesParams,
    nextPageIdentifier,
    setNextPageIdentifier,
    allArticles,
    setAllArticles,
    emptyGetArticlesParams,
    emptyArticlesFilter,
  } = useContext(ArticlesParamsContext);

  const [refreshing, setRefreshing] = useState(false);
  const { colorScheme } = useColorScheme();

  const [isLoading, setIsLoading] = useState(true);
  const [distributedArticles, setDistributedArticles] = useState<Article[][]>([]);
  const [paginatedArticles, setPaginatedArticles] = useState<Article[]>([]);
  const [pagination, setPagination] = useState<IPagination>(emptyPagination);

  const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false);

  const flatListRef = useRef<FlatList>(null);

  const fetchArticles = async (
    afterFetchCallback: (paginatedArticles: PaginatedResponse<Article>) => void,
  ) => {
    try {
      !firstPageLoaded && setFirstPageLoaded(true);
      setIsLoading(true);
      const paginatedArticlesResponse = await client.getArticles(getArticlesParams);
      afterFetchCallback(paginatedArticlesResponse);
      setPagination(paginatedArticlesResponse.pagination);
      setDistributedArticles(
        distributeElements(
          paginatedArticlesResponse.items,
          isWidthBelowSmall ? 1 : isWidthBelowLarge ? 2 : 3,
        ),
      );
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  useEffect(() => {
    setDistributedArticles(
      distributeElements(paginatedArticles, isWidthBelowSmall ? 1 : isWidthBelowLarge ? 2 : 3),
    );
  }, [isWidthBelowLarge, isWidthBelowSmall]);

  const afterMobileFetch = async (paginatedArticles: PaginatedResponse<Article>) => {
    const shouldAppend = getArticlesParams.page === nextPageIdentifier;
    setAllArticles((prev) => [...(shouldAppend ? prev : []), ...paginatedArticles.items]);
  };

  const afterDesktopFetch = async (paginatedArticles: PaginatedResponse<Article>) => {
    setPaginatedArticles(paginatedArticles.items);
  };

  const toggleBookmark = (article: Article) => {
    const setter = isMobile ? setAllArticles : setPaginatedArticles;

    setter((prev) =>
      prev.map((a) => (a.id === article.id ? { ...a, isBookmarked: !a?.isBookmarked } : a)),
    );

    if (!isMobile) {
      setDistributedArticles((prev) =>
        prev.map((row) =>
          row.map((a) => (a.id === article.id ? { ...a, isBookmarked: !a?.isBookmarked } : a)),
        ),
      );
    }
  };

  const onScrollEndReached = () => {
    const nextPage = pagination.currentPage + 1;

    if (nextPage <= pagination.pageCount) {
      setNextPageIdentifier(nextPage);
    }
  };

  const resetInfiniteScroll = () => {
    setNextPageIdentifier(0);
  };

  useEffect(() => {
    isMobile ? fetchArticles(afterMobileFetch) : fetchArticles(afterDesktopFetch);
  }, [getArticlesParams]);

  useEffect(() => {
    if (nextPageIdentifier > pagination.currentPage) {
      setGetArticlesParams((prev) => ({ ...prev, page: nextPageIdentifier }));
    }
  }, [nextPageIdentifier]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchArticles(afterMobileFetch);
    setTimeout(() => {
      setRefreshing(false);
    }, 800);
  }, []);

  const emptyArticlesState = (hard = true) => {
    setIsLoading(true);
    hard && setGetArticlesParams({ ...emptyGetArticlesParams });
    setAllArticles([]);
    setPaginatedArticles([]);
    resetInfiniteScroll();
    setPagination(emptyPagination);
    setIsLoading(false);
  };

  const mobileFeed = isLoading ? (
    <View className={cln('w-full h-full items-center justify-center', isTablet && 'pb-[320px]')}>
      <Loader />
    </View>
  ) : (
    <View
      style={{
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <FlatList
        data={allArticles}
        keyExtractor={(item) => item.id}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            progressViewOffset={-20}
            tintColor={colorScheme === 'dark' ? '#b0b0b0' : '#d1d1d1'}
            colors={colorScheme === 'dark' ? ['#b0b0b0'] : ['#f6f6f6']}
            progressBackgroundColor={
              colorScheme === 'dark' ? 'rgba(79,79,79,0.9)' : 'rgba(136, 136, 136,0.9)'
            }
          />
        }
        renderItem={(article) => (
          <EventCard
            item={article.item}
            toggleCardMark={toggleBookmark}
            styleProp={{
              flex: isTablet ? 0.5 : 1,
              marginBottom: 40,
            }}
          />
        )}
        onEndReached={onScrollEndReached}
        onEndReachedThreshold={0.8}
        initialNumToRender={emptyGetArticlesParams.perPage}
        ListFooterComponent={isLoading ? Loader : null}
        ref={flatListRef}
        numColumns={isTablet ? 2 : 1}
        columnWrapperStyle={isTablet ? { flex: 1, justifyContent: 'space-between', gap: 24 } : null}
        contentContainerStyle={{
          paddingHorizontal: 30,
          // Ez azert kell hogy betudjunk scrollozni a header moge
          paddingTop: !isTablet && navHeight + insets.top + adaptiveContainerPaddingTop,
        }}
      />
    </View>
  );

  const desktopFeed = isLoading ? (
    <Loader />
  ) : (
    <View>
      <View
        className="flex flex-row flex-wrap md:mx-[24px]"
        style={{
          rowGap: 64,
          columnGap: 24,
          justifyContent: 'flex-start',
          marginTop: isMobileWeb ? 24 : 0,
        }}
      >
        {distributedArticles.map((column, index) => (
          <View className={cln('flex-1')} key={index}>
            {column.map((article) => (
              <EventCard
                key={article.id}
                item={article}
                toggleCardMark={toggleBookmark}
                styleProp={{ marginBottom: isMobile ? 40 : 64 }}
              />
            ))}
          </View>
        ))}
      </View>
      <Pagination data={pagination} paramSetter={setGetArticlesParams} params={getArticlesParams} />
    </View>
  );

  const feed = isMobile ? mobileFeed : desktopFeed;

  const emptySearch = (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <>
      {!isLoading && (
        <View className={'flex-column'} style={{ display: isWeb ? 'inline' : 'flex' }}>
          <Text className="text-h3 md:text-h2 text-primaryLight-500 dark:text-primary-500 font-[sans-600]">
            {t('articles:no_results')}
          </Text>
          <Text className="text-h3 md:text-h2 text-neutral-950 dark:text-neutral-50 font-[sans-600]">
            {t('articles:based_on_your_search')}.
          </Text>
        </View>
      )}
    </>
  );
  return (
    <Layout
      title={!isMobile && t('articles:title')}
      isScrollView={!isMobile}
      onPressSearch={() =>
        navigation.navigate(ScreenName.PostFilterScreen, {
          fromScreen: ScreenName.ArticlesScreen,
        })
      }
      isListScreen={true}
    >
      {(getArticlesParams?.tags?.length >= 1 ||
        getArticlesParams.isBookmarked ||
        getArticlesParams.search) &&
      !isTablet &&
      isMobile ? (
        <View className="flex-row self-end px-[30px]">
          <Text className="text-neutral-950 dark:text-neutral-50 text-body font-[sans-700] mr-1.5">
            {allArticles.length}
          </Text>
          <Text className="text-neutral-950 dark:text-neutral-50 text-body font-[sans-400]">
            {t('articles:results')}
          </Text>
        </View>
      ) : (
        <></>
      )}
      {/* REMOVE ALL BUTTON */}
      {(getArticlesParams?.tags?.length >= 1 ||
        getArticlesParams.isBookmarked ||
        getArticlesParams.search) &&
      !isTablet &&
      isMobile ? (
        <View className="px-[30px]">
          <Pressable
            className="justify-center items-center"
            onPress={emptyArticlesFilter}
            style={{
              alignSelf: 'flex-end',
            }}
          >
            <Text className="text-primary-600 dark:text-primary-500 text-body font-[sans-400] py-2">
              {t('articles:remove_search')}
            </Text>
          </Pressable>
        </View>
      ) : (
        <></>
      )}

      {!isMobile || isTablet ? (
        <PostFilter
          getPostsParams={getArticlesParams}
          setGetPostsParams={setGetArticlesParams}
          resetInfiniteScroll={resetInfiniteScroll}
          allPosts={allArticles}
          pagination={pagination}
          screen={ScreenName.ArticlesScreen}
        />
      ) : (
        <></>
      )}
      {firstPageLoaded && pagination.total === 0 ? emptySearch : feed}
    </Layout>
  );
};

export default ArticlesScreen;
